 //@import '../../utils/variables';
 //@import "~bootstrap-4.0.0/scss/mixins/_breakpoints.scss";

.slidersukcesu.slidersukcesu--fade {
    background: black;
	@media only screen and (min-width: 750px) and (max-width:1024px) {
		margin-top: 6rem;
	}
    .carousel-item {
		height: 80rem;

		@media only screen and (max-width: 1199.98px) {
			height: auto;
			display: flex;
			justify-content: center;
			background-image: url('../../../../../images/strona-glowna/slider/wyposazenie-gabinetow-kosmetycznych-3.jpg') ;
			height: calc(100vh - 60px - 120px + 1.5rem);
			min-height: 300px;
			background-size: cover ;
			background-position-x: center;
			background-repeat: no-repeat;
		}
        img {
			opacity: 1;
			width: 100%;
			@media only screen and (max-width: 1199.98px) {
				display: none;
			}
		}
		
		&::after {
			content: '';
			display: block;
			background: black;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0.32;
		}
	}

	.carousel-caption {
		left: 0;
		right: 0;
		top: 7rem;
		bottom: 30rem;
		@media only screen and (max-width: 1199.98px) {
			top: 50%;
			bottom: 0;
			transform: translateY(-50%);
			.row{
				.col-12{
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
				}
			}
		}

		.row {
			height: 100%;
			> div {
				height: 100%;
			}
		}
	}

	.carousel-control-prev,
	.carousel-control-next {
		.material-icons {
			width: 65px;
		}
	}

	.carousel-control-prev {
		justify-content: flex-end;
	}

	.carousel-control-next {
		justify-content: flex-start;
	}

	.slide {
		&__title {
			font-weight: 900;
			font-size: 8rem;
			line-height: 9.6rem;
			color: white;
			@media only screen and (max-width: 1199.98px) {
				font-size: 6rem;
				line-height: 7.6rem;
			}
			@media (max-width: 767.98px) {
				font-size: 3rem;
				line-height: 4.6rem;
			}
			@media screen and (max-height: 459px){
				margin-bottom: 0;
			}

		}

		&__description {
			font-weight: 900;
			font-size: 4.3rem;
			line-height: 4.4rem;
			@media only screen and (max-width: 1199.98px) {
			 font-size: 3.3rem;
			}
			@media (max-width: 767.98px) {
				font-size: 2rem;
				line-height: 3.6rem;
			}
		}
		
		&__button {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: 0;
			@media only screen and (max-width: 1199.98px) {
				position: static;
				transform: translateX(0);
			}
			
			@media screen and (max-height: 459px){
				display: none !important;
			}
		}
	}
}
